import React from 'react';
import { connect } from "react-redux";

import '../css/Terms.css'

class Terms extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="terms">
                <div className="terms-inner-div">
                    <h2>Terms of Servicey</h2>
                    <hr />

                    <h4>Introduction</h4>
                    <br />
                    <p>The Terms and Conditions (“<b>Terms”</b>) describe the use and regulation of “Facebook Content Analyzer”. Please read the following information carefully to understand our practices regarding your use of the Site. The platform may change the Terms at any time. The platform may inform you of the changes to the Terms using the available means of communication. The platform recommends you to check the Site frequently to see the actual version of the Terms and their previous versions.
                    If you represent a legal entity, you certify that you entitled by such a legal entity to conclude the Terms as the legal entity you represent.</p>
                    <hr />

                    <h4>1. PRIVACY POLICY</h4>
                    <br />
                    <p>Our Privacy Policy explains to you how we process information about you. You shall understand that through your use of the Site you acknowledge the processing of this information shall be undertaken in accordance with the Privacy Policy.</p>
                    <hr />

                    <h4>2. YOUR ACCOUNT</h4>
                    <br />
                    <p>When using the Site, you shall be responsible for ensuring the confidentiality of your account, password and other credentials and for secure access to your device. You shall not assign your account to anyone. The platform is not responsible for unauthorized access to your account that results from misappropriation or theft of your account. The platform may refuse or cancel service, terminate your account, and remove or edit content.
                    The platform does not knowingly collect personal data from persons under the age of 16 (sixteen). If you are under 16 (sixteen) years old, you may not use the platform and may not enter into the Terms under any circumstances.</p>
                    <hr />

                    <h4>4. THIRD PARTY SERVICES</h4>
                    <br />
                    <p>The Site may include links to other sites, applications, and platforms (hereinafter the “<b>Linked Sites</b>“).
                    The platform does not control the Linked Sites, and shall not be responsible for the content and other materials of the Linked Sites. The platform makes these links available to you for providing the functionality or services on the Site.</p>
                    <hr />

                    <h4>5. PROHIBITED USES AND INTELLECTUAL PROPERTY</h4>
                    <br />
                    <p>The platform grants you a non-transferable, non-exclusive, revocable license to access and use the Site from one device in accordance with the Terms.<br/>
                    You shall not use the Site for unlawful or prohibited purpose. You may not use the Site in a way that may disable, damage, or interfere in the Site.<br/>
                    All content present on the Site includes text, code, graphics, logos, images, compilation, software used on the Site (hereinafter and hereinbefore the “Content“). The Content is the property of the platform or its respective owner and protected by intellectual property laws that protect such rights. You agree to use all copyright and other proprietary notices or restrictions contained in the Content and you are prohibited from changing the Content.<br/>
                    You may not publish, transmit, modify, reverse engineer, participate in the transfer, or create and sell derivative works, or in any way use any of the Content. Your enjoyment of the Site shall not entitle you to make any illegal and disallowed use of the Content, and in particular you shall not change proprietary rights or notices in the Content. You shall use the Content only for your personal and non-commercial use. The platform does not grant you any licenses to the intellectual property of the platform or from the respective owners.</p>
                    <hr />

                    <h4>6. DISCLAIMER OF CERTAIN LIABILITIES</h4>
                    <br />
                    <p>The information available via the Site may include typographical errors or inaccuracies. The platform shall not be liable for these inaccuracies and errors.<br/>
                    The platform makes no representations about the availability, accuracy, reliability, suitability, and timeliness of the Content contained on and services available on the Site. To the maximum extent allowed by the applicable law, all such Content and services are provided on the “as is” basis. The platform disclaims all warranties and conditions regarding this Content and services, including warranties and provisions of merchantability, fitness for a certain purpose.<br/>
                    To the maximum extent permitted by the applicable law, in no event shall the platform be liable for any direct, indirect, incidental, consequential, special, punitive damages including, but not limited to, damages for loss of enjoyment, data or profits, in the connection with the enjoyment or execution of the Site in the context of the inability or delay to enjoy the Site or its services, or for any Content of the Site, or otherwise arising out of the enjoyment of the Site, based on contract and non-contract liability or other reason.<br/>
                    If the exclusion or limitation of liability for damages, whether consequential or incidental, are prohibited in a particular case, the exclusion or limitation of liability shall not apply to you.</p>
                    <hr />

                    <h4>7. INDEMNIFICATION</h4>
                    <br />
                    <p>You agree to indemnify, defend and hold harmless the platform, its managers, directors, employees, agents, and third parties, for any costs, losses, expenses (including attorneys’ fees), liabilities regarding or arising out of your enjoyment of or inability to enjoy the Site or its services and platform’s services and products, your violation of the Terms or your violation of any rights of third parties, or your violation of the applicable law. The may assume the exclusive defence and you shall cooperate with the platform in asserting any available defences.</p>
                    <hr />

                    <h4>8. TERMINATION AND ACCESS RESTRICTION</h4>
                    <br />
                    <p>The platform may terminate your access and account to the Site and its related services or any part at any time, without notice, in case of your violation of the Terms.</p>
                    <hr />

                    <h4>9. MISCELLANEOUS</h4>
                    <br />
                    <p>The governing law of the Terms shall be the substantive laws of the country where the platform is set up, except the conflict of laws rules. You shall not use the Site in jurisdictions that do not give effect to all provisions of the Terms.<br />
                    No joint venture, partnership, employment, or agency relationship shall be implied between you and the platform as a result of the Terms or use of the Site.<br />
                    Nothing in the Terms shall be a derogation of the platform’s right to comply with governmental, court, police, and law enforcement requests or requirements regarding your enjoyment of the Site.<br />
                    If any part of the Terms is determined to be void or unenforceable in accordance with applicable law then the void or unenforceable clauses will be deemed superseded by valid and enforceable clauses shall be similar to the original version of the Terms and other parts and sections of the Terms shall be applicable to you and the platform.<br />
                    The Terms constitute the entire agreement between you and the platform regarding the enjoyment of the Site and the Terms supersede all prior or communications and offers, whether electronic, oral or written, between you and the platform.<br />
                    The platform and its affiliates shall not be liable for a failure or delay to fulfil its obligations where the failure or delay results from any cause beyond platform’s reasonable control, including technical failures, natural disasters, blockages, embargoes, riots, acts, regulation, legislation, or orders of government, terroristic acts, war, or any other force outside of platform’s control.<br />
                    In case of controversies, demands, claims, disputes, or causes of action between the platform and you relating to the Site or other related issues, or the Terms, you and the platform agree to attempt to resolve such controversies, demands, claims, disputes, or causes of action by good faith negotiation, and in case of failure of such negotiation, exclusively through the courts of the country where the platform is set up.</p>
                    <hr />

                    <h4>10. Complaints</h4>
                    <br />
                    <p>We are committed to resolve any complaints about our collection or use of any personal data. If you would like to make a complaint regarding this Terms or our practices in relation to your personal data, please contact us at info@mobilecardspro.com. We will reply to your complaint as soon as we can and in any event, within 30 days. We hope to resolve any complaint brought to our attention, however if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority</p>
                    <hr />

                    <h4>11. Contact Information</h4>
                    <br />
                    <p>We welcome your comments or questions about this Terms. You may contact us in writing at:
                    Email: support ( at ) info@mobilecardspro.com</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Terms);