import axios from 'axios';
import URL from '../api.json';
import Config from '../../config'

const GlobalAPIConfig = {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    responseType: 'json'
}

export const getFBPost = (params) => {
    let url = URL.getPostAPI.replace('{pageId}', Config.PAGE_ID)

    return axios.get(url+Config.ACTION_TOKEN)
            .then((res) => {
                return res
            })
            .catch(function (error) {
                console.error(error);
            });
}

export const getFBShop = (params) => {
    let url = URL.getShopAPI.replace('{pageId}', Config.PAGE_ID)

    return axios.get(url+Config.ACTION_TOKEN)
            .then((res) => {
                return res
            })
            .catch(function (error) {
                console.error(error);
            });
}

export const getFBAnalysis = (params) => {
    let url = URL.getAnalysisAPI.replace('{pageId}', Config.PAGE_ID)

    return axios.get(url)
        .then((res) => {
            return res
        })
        .catch(function (error) {
            console.error(error);
        });
}