import React from 'react';
import { connect } from "react-redux";
import { TiTick } from "react-icons/ti";
import { FaRegCopy } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip'
import _ from "lodash";

import '../css/Result.css'
import 'react-toastify/dist/ReactToastify.css';

class Result extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(this.props.analysis);
    }

    hrefToAnalyzer = () => {
        window.location = "/analyzer"
    }

    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        toast.success("\"" + text + "\" is Copied !")
    }

    render() {
        return (
            <div className="result">
                <ToastContainer />
                <div className="cover-img-div">
                    <img className="cover-img" src={this.props.analysis.shop.cover.source} />
                </div>
                <div className="cover-img-div-overlay">
                </div>

                <div className="overview-div">
                    {/* Shop brief information overview */}
                    <div className="overview-brief-div">
                        <div className="row overview-row">
                            <div className="col-md-3 col-12">
                                <img className="favicon-img" src={this.props.analysis.shop.picture.data.url} />
                            </div>
                            <div className="col-md-9 col-12 overview-row-right">
                                <h1>{this.props.analysis.shop.name}</h1>
                                <h5 className="subtitle">{this.props.analysis.shop.category}</h5>
                            </div>
                        </div>

                        <br />
                        <hr className="hr"/>

                        <div className="row overview-breif-row">
                            <div className="col-12 col-md-4 overview-breif-row-col">
                                <h5>Username</h5>
                                <p className="provided-p">{this.props.analysis.shop.name}</p>
                                {
                                    (this.props.analysis.shop.name !== undefined && this.props.analysis.shop.name !== null && this.props.analysis.shop.name !== '') ?
                                    <div className="provided-div">
                                        <TiTick color="white" fontSize="20px"/>
                                        provided
                                    </div>
                                    :null
                                }
                            </div>
                            <div className="col-12 col-md-4 overview-breif-row-col">
                                <h5>Profile Picture & Cover Photo</h5>
                                <p className="provided-p"></p>
                                {
                                    (this.props.analysis.shop.cover.source !== undefined && this.props.analysis.shop.cover.source !== null && this.props.analysis.shop.cover.source !== '' && this.props.analysis.shop.picture.data.url !== undefined && this.props.analysis.shop.picture.data.url !== null && this.props.analysis.shop.picture.data.url !== '') ?
                                    <div className="provided-div">
                                        <TiTick color="white" fontSize="20px"/>
                                        provided
                                    </div>
                                    :null
                                }
                            </div>
                            <div className="col-12 col-md-4 overview-breif-row-col">
                                <h5>About</h5>
                                <p className="provided-p">{
                                    (this.props.analysis.shop.description.length > 12) ?this.props.analysis.shop.description.substring(0, 12)+'...'
                                    :this.props.analysis.shop.description
                                }</p>
                                {
                                    (this.props.analysis.shop.description !== undefined && this.props.analysis.shop.description !== null && this.props.analysis.shop.description !== '') ?
                                    <div className="provided-div">
                                        <TiTick color="white" fontSize="20px"/>
                                        provided
                                    </div>
                                    :null
                                }
                            </div>
                        </div>
                    </div>

                    {/* Shop top 10 words */}
                    <div className="overview-top10-words-div">
                        <h3>Top 10 word count</h3>
                        <hr />
                        <h5>Most used words on the page:</h5>
                        <div className="row">
                            {
                                _.size(this.props.analysis.words) > 0 ?
                                _.keys(this.props.analysis.words).map((word, index) => (
                                    <div key={index} className="tag">
                                        <h5 className="tag-h5">{word}</h5>
                                        <div className="tag-number">
                                            <h5 className="tag-h5">{_.values(this.props.analysis.words)[index]}</h5>
                                        </div>
                                        <div className="tag-number copy" onClick={() => this.copyToClipboard(word)}>
                                            <FaRegCopy color="black" />
                                        </div>
                                    </div>
                                ))
                                :null
                            }
                        </div>
                    </div>

                    {/* Shop top 10 emoji */}
                    <div className="overview-top10-emoji-div">
                        <h3>Top 10 Emoji</h3>
                        <hr />

                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Total number of emoji: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.emoji_original_count}</h5>
                            </div>
                        </div>
                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Total number of unique emoji: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.emoji_processed_count}</h5>
                            </div>
                        </div>
                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Emoji per post: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.emoji_original_count / _.size(this.props.analysis.post.data)}</h5>
                            </div>
                        </div>

                        <hr />

                        <div className="emoji-row">
                            <h5>Most used Emoji on the pages:</h5>
                        </div>
                        <div className="row">
                            {
                                _.size(this.props.analysis.emoji) > 0 ?
                                _.keys(this.props.analysis.emoji).map((emoji, index) => (
                                    <div key={index} className="tag">
                                        <h5 className="tag-h5">{emoji}</h5>
                                        <div className="tag-number">
                                            <h5 className="tag-h5">{_.values(this.props.analysis.emoji)[index]}</h5>
                                        </div>
                                        <div className="tag-number copy" onClick={() => this.copyToClipboard(emoji)}>
                                            <FaRegCopy color="black" />
                                        </div>
                                    </div>
                                ))
                                :null
                            }
                        </div>
                    </div>

                    {/* Shop top 10 hashtag */}
                    <div className="overview-top10-hashtag-div">
                        <h3>Top 10 Hashtags</h3>
                        <hr />

                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Total number of hastags: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.tag_original_count}</h5>
                            </div>
                        </div>
                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Total number of unique hastags: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.tag_processed_count}</h5>
                            </div>
                        </div>
                        <div className="emoji-row">
                            <h5 className="emoji-row-h5">Total number of hastags: </h5>
                            <div className="emoji-row-value">
                                <h5>{this.props.analysis.tag_original_count / _.size(this.props.analysis.post.data)}</h5>
                            </div>
                        </div>

                        <hr />

                        <div className="emoji-row">
                            <h5>Most used Hashtags on the pages:</h5>
                        </div>
                        <div className="row">
                            {
                                _.size(this.props.analysis.tag) > 0 ?
                                _.keys(this.props.analysis.tag).map((tag, index) => (
                                    <div key={index} className="tag">
                                        <h5 className="tag-h5">#{tag}</h5>
                                        <div className="tag-number">
                                            <h5 className="tag-h5">{_.values(this.props.analysis.tag)[index]}</h5>
                                        </div>
                                        <div className="tag-number copy" onClick={() => this.copyToClipboard(tag)}>
                                            <FaRegCopy color="black" />
                                        </div>
                                    </div>
                                ))
                                :null
                            }
                        </div>
                    </div>

                    {/* Shop top 10 reactions post */}
                    {
                        _.size(this.props.analysis.reactions) > 0 ?
                        <div className="overview-top10-reaction-post-div">
                            <h3>Top 10 reactions of posts</h3>
                            <hr />
                            
                            <table>
                                <tr className="tr">
                                    <th>Post Message</th>
                                    <th>Reactions</th>
                                </tr>
                                {
                                    this.props.analysis.reactions.map((post, index) => (
                                        <tr className={(index % 2) ?'tr' :'grey-tr'}>
                                            <td className="left-td">
                                                <a href={post.permalink_url} target="_blank" data-tip data-for={'message' + index}>
                                                    {
                                                        (post.message.length > 35) ?
                                                        post.message.substring(0, 35) + '...'
                                                        :post.message
                                                    }
                                                </a>
                                                <p className="grey-p">{post.created_time.substring(0, 10)}</p>
                                                <ReactTooltip id={'message' + index} place="right" type='info' effect="float">
                                                    <span>{post.message}</span>
                                                </ReactTooltip>
                                            </td>
                                            <td>{_.size(post.reactions.data)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        :null
                    }

                    {/* Shop top 10 likes post */}
                    {
                        _.size(this.props.analysis.likes) > 0 ?
                        <div className="overview-top10-reaction-post-div">
                            <h3>Top 10 likes of posts</h3>
                            <hr />
                            
                            <table>
                                <tr className="tr">
                                    <th>Post Message</th>
                                    <th>Likes</th>
                                </tr>
                                {
                                    this.props.analysis.likes.map((post, index) => (
                                        <tr className={(index % 2) ?'tr' :'grey-tr'}>
                                            <td className="left-td">
                                                <a href={post.permalink_url} target="_blank" data-tip data-for={'likesmessage' + index}>
                                                    {
                                                        (post.message.length > 35) ?
                                                        post.message.substring(0, 35) + '...'
                                                        :post.message
                                                    }
                                                </a>
                                                <p className="grey-p">{post.created_time.substring(0, 10)}</p>
                                                <ReactTooltip id={'likesmessage' + index} place="right" type='info' effect="float">
                                                    <span>{post.message}</span>
                                                </ReactTooltip>
                                            </td>
                                            <td>{_.size(post.likes.data)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        :null
                    }

                    {/* Shop top 10 shares post */}
                    {
                        _.size(this.props.analysis.likes) > 0 ?
                        <div className="overview-top10-reaction-post-div">
                            <h3>Top 10 shares of posts</h3>
                            <hr />
                            
                            <table>
                                <tr className="tr">
                                    <th>Post Message</th>
                                    <th>Shares</th>
                                </tr>
                                {
                                    this.props.analysis.shares.map((post, index) => (
                                        <tr className={(index % 2) ?'tr' :'grey-tr'}>
                                            <td className="left-td">
                                                <a href={post.permalink_url} target="_blank" data-tip data-for={'sharesmessage' + index}>
                                                    {
                                                        (post.message.length > 35) ?
                                                        post.message.substring(0, 35) + '...'
                                                        :post.message
                                                    }
                                                </a>
                                                <p className="grey-p">{post.created_time.substring(0, 10)}</p>
                                                <ReactTooltip id={'sharesmessage' + index} place="right" type='info' effect="float">
                                                    <span>{post.message}</span>
                                                </ReactTooltip>
                                            </td>
                                            <td>{post.shares.count}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                        </div>
                        :null
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    post: state.common.post,
    shop: state.common.shop,
    analysis: state.common.analysis
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);