import React from 'react';
import { connect } from "react-redux";
import {
    Button
} from 'react-bootstrap';

import '../css/Home.css'

class Home extends React.Component {
    constructor(props) {
        super(props);
    }

    hrefToAnalyzer = () => {
        window.location = "/analyzer"
    }

    render() {
        return (
            <div className="home">
                <div className="top-banner">
                    <h1>Facebook Content Analyzer</h1>
                    <h4 className="subtitle">The Analyzer For Your Facebook Page</h4>
                    <br />
                    <Button className="button" variant="primary" onClick={this.hrefToAnalyzer}>Analyze Now!</Button>
                </div>

                <div className="content row">
                    <div className="content-left col-6">
                        <h2>Discover the most frequent topic</h2>
                    </div>
                    <div className="col-6">
                        <img className="img" src={require('../images/Demo1.png')} />
                    </div>
                </div>

                <hr />

                <div className="content row">
                    <div className="content-left col-6">
                        <img className="img" src={require('../images/Demo2.png')} />
                    </div>
                    <div className="content-right col-6">
                        <h2>Extract the post information</h2>
                    </div>
                </div>

                <hr />

                <div className="content row">
                    <div className="content-left col-6">
                        <h2>Check your popular posts</h2>
                    </div>
                    <div className="col-6">
                        <img className="img" src={require('../images/Demo3.png')} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);