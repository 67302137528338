export const GET_FB_POST = '@@common/GET_FB_POST';
export const GET_FB_POST_CANCELED = '@@common/GET_FB_POST_CANCELED';
export const GET_FB_POST_REQUIRED = '@@common/GET_FB_POST_REQUIRED';
export const GET_FB_POST_REJECTED = '@@common/GET_FB_POST_REJECTED';

export const GET_FB_SHOP = '@@common/GET_FB_SHOP';
export const GET_FB_SHOP_CANCELED = '@@common/GET_FB_SHOP_CANCELED';
export const GET_FB_SHOP_REQUIRED = '@@common/GET_FB_SHOP_REQUIRED';
export const GET_FB_SHOP_REJECTED = '@@common/GET_FB_SHOP_REJECTED';

export const GET_FB_ANALYSIS = '@@common/GET_FB_ANALYSIS';
export const GET_FB_ANALYSIS_CANCELED = '@@common/GET_FB_ANALYSIS_CANCELED';
export const GET_FB_ANALYSIS_REQUIRED = '@@common/GET_FB_ANALYSIS_REQUIRED';
export const GET_FB_ANALYSIS_REJECTED = '@@common/GET_FB_ANALYSIS_REJECTED';