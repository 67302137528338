import { createAction } from 'typesafe-actions';
import * as TYPES from '../contants/Types'

// Get facebook post
export const getFBPost = createAction(
    TYPES.GET_FB_POST,
    (resolve) => (params) => resolve({ params })
)
export const getFBPostCanceled = createAction(
    TYPES.GET_FB_POST_CANCELED,
    (resolve) => () => resolve()
)
export const getFBPostRequired = createAction(
    TYPES.GET_FB_POST_REQUIRED,
    (resolve) => (post) => resolve({ data: { post } })
)
export const getFBPostRejected = createAction(
    TYPES.GET_FB_POST_REJECTED,
    (resolve) => (error) => resolve({ error })
)

// Get facebook shop
export const getFBShop = createAction(
    TYPES.GET_FB_SHOP,
    (resolve) => (params) => resolve({ params })
)
export const getFBShopCanceled = createAction(
    TYPES.GET_FB_SHOP_CANCELED,
    (resolve) => () => resolve()
)
export const getFBShopRequired = createAction(
    TYPES.GET_FB_SHOP_REQUIRED,
    (resolve) => (shop) => resolve({ data: { shop } })
)
export const getFBShopRejected = createAction(
    TYPES.GET_FB_SHOP_REJECTED,
    (resolve) => (error) => resolve({ error })
)

// Get facebook analysis
export const getFBAnalysis = createAction(
    TYPES.GET_FB_ANALYSIS,
    (resolve) => (params) => resolve({ params })
)
export const getFBAnalysisCanceled = createAction(
    TYPES.GET_FB_ANALYSIS_CANCELED,
    (resolve) => () => resolve()
)
export const getFBAnalysisRequired = createAction(
    TYPES.GET_FB_ANALYSIS_REQUIRED,
    (resolve) => (analysis) => resolve({ data: { analysis } })
)
export const getFBAnalysisRejected = createAction(
    TYPES.GET_FB_ANALYSIS_REJECTED,
    (resolve) => (error) => resolve({ error })
)