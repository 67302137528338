import React from 'react';
import { 
    Navbar,
    Nav,
    NavDropdown,
    Form,
    FormControl,
    Button
} from 'react-bootstrap'
import {withRouter} from 'react-router-dom';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Navbar bg="dark" expand="lg" variant="dark">
                <Navbar.Brand href="/">Facebook Content Analyzer</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav >
                        <Nav.Link href="/analyzer" active={(this.props.location.pathname === "/analyzer") ?true :false}>Analyzer</Nav.Link>
                        <Nav.Link href="/privacy" active={(this.props.location.pathname === "/privacy") ?true :false}>Privacy Policy</Nav.Link>
                        <Nav.Link href="/tnc" active={(this.props.location.pathname === "/tnc") ?true :false}>Terms of Service</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default withRouter(TopBar);