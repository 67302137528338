import { applyMiddleware, compose, createStore } from 'redux'
import { persistReducer, persistStore, createTransform } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import epics from '../api/epics';


export default (initialState) => {
    const config = {
        key: 'root',
        storage: storage,
    }

    const reducers = persistReducer(config, rootReducer);

    const epicMiddleware = createEpicMiddleware();

    const middlewares = [epicMiddleware];

    const composeEnhancers = (
        window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) || compose;

    const enhancer = composeEnhancers(
        applyMiddleware(...middlewares),
    );

    const store = createStore(
        reducers,
        initialState,
        enhancer
    );

    const persistor = persistStore(
        store,
        undefined,
    );

    epicMiddleware.run(epics);

    return { store, persistor }
}