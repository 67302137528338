import React from 'react';
import { connect } from "react-redux";

import '../css/Privacy.css'

class Privacy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="privacy">
                <div className="privacy-inner-div">
                    <h2>Privacy Policy</h2>
                    <hr />

                    <h4>Introduction</h4>
                    <br />
                    <p>This privacy policy (“<b>Policy”</b>) describes how “Facebook Content Analyzer” processes, collects, uses and shares personal data when using this platform. Please read the following information carefully to understand our practices regarding your personal data and how we will process data.</p>
                    <hr />

                    <h4>1. Purposes of Processing</h4>
                    <br />
                    <h6><b>What is personal data?</b></h6>
                    <p>We collect information about you in a range of forms, including personal data. As used in this Policy, “personal data” is as defined in the General Data Protection Regulation, this includes any information which, either alone or in combination with other information we process about you, identifies you as an individual, including, for example, your name, postal address, email address and telephone number.</p>
                    <h6><b>Why do we need your personal data?</b></h6>
                    <p>We will only process your personal data in accordance with applicable data protection and privacy laws. We need certain personal data in order to provide you with access to the Site. If you registered with us, you will have been asked to tick to agree to provide this information in order to access our services, purchase our products, or view our content. This consent provides us with the legal basis we require under applicable law to process your data. You maintain the right to withdraw such consent at any time. If you do not agree to our use of your personal data in line with this Policy, please do not use our Site.</p>                
                    <hr />

                    <h4>2. Collecting Your Personal Data</h4>
                    <br />
                    <p>We collect information about you in the following ways:</p>
                    <p><b>Information You Give Us.</b> This includes:</p>
                    <ul>
                        <li>the personal data you provide when you register to use our Site, including your‎ name, postal address, email address, username, password;</li>
                        <li>the personal data you provide when you report a problem with our Site or when we provide you with customer support;</li>
                        <li>the personal data you provide when you correspond with us by phone, email or otherwise.</li>
                    </ul>
                    <p><b>Information Automatically Collected.</b> We automatically log information about you and your computer or mobile device when you access our Site. For example, when visiting our Site, we log your computer or mobile device operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the website you visited before browsing to our Site, pages you viewed, how long you spent on a page, access times and information about your use of and actions on our Site. We collect this information about you using cookies.</p>
                    <hr />
                
                    <h4>3. Using Your Personal Data</h4>                
                    <br />
                    <p>We may use your personal data as follows:</p>
                    <ul>
                        <li>to operate, maintain, and improve our Site, products, and services;</li>
                        <li>to manage your account, including to communicate with you regarding your account, if you have an account on our Site;</li>
                        <li>to send information including technical notices, updates, security alerts, and support and administrative messages;</li>
                        <li>with your consent, to send you marketing e-mails about upcoming promotions, and other news, including information about products and services offered by us and our affiliates. You may opt-out of receiving such information at any time: such marketing emails tell you how to “opt-out.” Please note, even if you opt out of receiving marketing emails, we may still send you non-marketing emails. Non-marketing emails include emails about your account with us (if you have one) and our business dealings with you;</li>
                        <li>as we believe necessary or appropriate (a) to comply with applicable laws; (b) to comply with lawful requests and legal process, including to respond to requests from public and government authorities; (c) to enforce our Policy; and (d) to protect our rights, privacy, safety or property, and/or that of you or others;</li>
                        <li>for analysis and study services; and</li>
                        <li>as described in the “Sharing of your Personal Data” section below.</li>
                    </ul>
                    <hr />

                    <h4>4. International Data Transfer</h4>       
                    <br />
                    <p>Your information, including personal data that we collect from you, may be transferred to, stored at and processed by us outside the country in which you reside, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By accepting this Policy, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Policy.</p>    
                    <hr />
                
                    <h4>5. Security</h4>       
                    <br />
                    <p>We seek to use reasonable organizational, technical and administrative measures to protect personal data within our organization. Unfortunately, no transmission or storage system can be guaranteed to be completely secure, and transmission of information via the internet is not completely secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us.</p> 
                    <hr />

                    <h4>6. Retention</h4>       
                    <br />
                    <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements unless a longer retention period is required or permitted by law (for example for regulatory purposes).</p>
                    <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
                    <p>We will retain the minimum amount of data required for us to supply you with relevant marketing content or in performance of a contract that we have entered into.</p>
                    <p>Should you no longer do business with us please notify us so that we can remove your data.</p>
                    <p>We may also retain your data to ensure you are no longer contacted if you wish to opt-out, or to record that your email address is no longer in operation.</p>
                    <p>If you wish to exercise any of the rights set out above, please contact us at: info@mobilecardspro.com</p>
                    <hr />

                    <h4>7. Our Policy on Children</h4>       
                    <br />
                    <p>Our Site is/are not directed to children under 16. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us. We will delete such information from our files as soon as reasonably practicable.</p>
                    <hr />

                    <h4>8. Your Rights</h4>
                    <br />
                    <ul>
                        <li>Opt-out. You may contact us anytime to opt-out of: (i) marketing communications; (ii) automated decision-making and/or profiling; (iii) our collection of sensitive personal data; (iv) any new processing of your personal data that we may carry out beyond the original purpose; or(v) the transfer of your personal data. Please note that your use of some of the Site may be ineffective upon opt-out.</li>
                        <li>You may access the information we hold about you at any time via your profile/account or by contacting us directly.</li>
                        <li>You can also contact us to update or correct any inaccuracies in your personal data.</li>
                        <li>Your personal data is portable – i.e. you to have the flexibility to move your data to other service providers as you wish.</li>
                        <li>Erase and forget. In certain situations, for example when the information we hold about you is no longer relevant or is incorrect, you can request that we erase your data.</li>
                        <li>If you wish to exercise any of these rights, please contact us. In your request, please make clear: (i) what personal data is concerned; and (ii) which of the above rights you would like to enforce. For your protection, we may only implement requests with respect to the personal data associated with the particular email address that you use to send us your request, and we may need to verify your identity before implementing your request. We will try to comply with your request as soon as reasonably practicable and in any event, within one month of your request. Please note that we may need to retain certain information for recordkeeping purposes and/or to complete any transactions that you began prior to requesting such change or deletion.</li>
                    </ul>
                    <hr />

                    <h4>9. Complaints</h4>
                    <br />
                    <p>We are committed to resolve any complaints about our collection or use of your personal data. If you would like to make a complaint regarding this Policy or our practices in relation to your personal data, please contact us at: info@mobilecardspro.com. We will reply to your complaint as soon as we can and in any event, within 30 days. We hope to resolve any complaint brought to our attention, however if you feel that your complaint has not been adequately resolved, you reserve the right to contact your local data protection supervisory authority</p>
                    <hr />

                    <h4>10. Contact Information</h4>
                    <br />
                    <p>We welcome your comments or questions about this Terms. You may contact us in writing at:
                    Email: info@mobilecardspro.com</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);