import {
    Observable,
    of ,
    forkJoin,
    Rx,
    from
} from 'rxjs';
import {
    mergeMap,
    concatMap,
    withLatestFrom,
    map,
    catchError,
    takeUntil
} from 'rxjs/operators';
import {
    Epic,
    ofType
} from 'redux-observable';

import * as TYPES from '../../redux/contants/Types';
import * as ACTIONS from '../../redux/actions'
import * as SERVICES from '../services/CommonService'

const getFBShopEpic = (action$, state$) => action$.pipe(
    ofType(TYPES.GET_FB_SHOP),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const params = action.payload.params;

        return SERVICES.getFBShop(params).pipe(
            map((res) => {
                console.log(res.data);
                if(!res) {
                    return ACTIONS.getFBShopRejected('Network error!');
                }
                return ACTIONS.getFBShopRequired(res.data)
            }),
            catchError((error) => of(ACTIONS.getFBShopRejected('Network error!'))),
            takeUntil(action$.pipe(ofType(TYPES.GET_FB_SHOP_CANCELED)))
        )
    })
)

const getFBPostEpic = (action$, state$) => action$.pipe(
    ofType(TYPES.GET_FB_POST),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const params = action.payload.params;

        return SERVICES.getFBPost(params).pipe(
            map((res) => {
                console.log(res.data);
                if(!res) {
                    return ACTIONS.getFBPostRejected('Network error!');
                }
                return ACTIONS.getFBPostRequired(res.data)
            }),
            catchError((error) => of(ACTIONS.getFBPostRejected('Network error!'))),
            takeUntil(action$.pipe(ofType(TYPES.GET_FB_POST_CANCELED)))
        )
    })
)

const getFBAnalysisEpic = (action$, state$) => action$.pipe(
    ofType(TYPES.GET_FB_ANALYSIS),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
        const params = action.payload.params;

        return SERVICES.getFBAnalysis(params).pipe(
            map((res) => {
                console.log(res);
                if(!res) {
                    return ACTIONS.getFBAnalysisRejected('Network error!');
                }
                return ACTIONS.getFBAnalysisRequired(res.data)
            }),
            catchError((error) => of(ACTIONS.getFBAnalysisRejected('Network error!'))),
            takeUntil(action$.pipe(ofType(TYPES.GET_FB_ANALYSIS_CANCELED)))
        )
    })
)

export default [
    getFBShopEpic,
    getFBPostEpic,
    getFBAnalysisEpic
];