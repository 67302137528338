import * as TYPES from '../contants/Types'

const initialState = {
    postLoading: false,
    shopLoading: false,
    loading: false,
    error: null,

    post: [],
    shop: [],
    analysis: [],
}

export default(state = initialState, action) => {
    switch(action.type) {
        // Get facebook post
        case TYPES.GET_FB_POST: {
            return {
                ...state,
                postLoading: true,
                post: initialState.post
            }
        }
        case TYPES.GET_FB_POST_CANCELED: {
            return {
                ...state,
                postLoading: false,
            }
        }
        case TYPES.GET_FB_POST_REQUIRED: {
            if(action.payload.data) {
                const { post } = action.payload.data
                return {
                    ...state,
                    postLoading: false,
                    post
                }
            }
        }
        case TYPES.GET_FB_POST_REJECTED: {
            return {
                ...state,
                postLoading: false,
                error: action.payload.error
            }
        }

        // Get facebook shop
        case TYPES.GET_FB_SHOP: {
            return {
                ...state,
                shopLoading: true,
                shop: initialState.post
            }
        }
        case TYPES.GET_FB_SHOP_CANCELED: {
            return {
                ...state,
                shopLoading: false,
            }
        }
        case TYPES.GET_FB_SHOP_REQUIRED: {
            if(action.payload.data) {
                const { shop } = action.payload.data
                return {
                    ...state,
                    shopLoading: false,
                    shop
                }
            }
        }
        case TYPES.GET_FB_SHOP_REJECTED: {
            return {
                ...state,
                shopLoading: false,
                error: action.payload.error
            }
        }

        // Get facebook analysis
        case TYPES.GET_FB_ANALYSIS: {
            return {
                ...state,
                loading: true,
                analysis: initialState.analysis
            }
        }
        case TYPES.GET_FB_ANALYSIS_CANCELED: {
            return {
                ...state,
                loading: false,
            }
        }
        case TYPES.GET_FB_ANALYSIS_REQUIRED: {
            if(action.payload.data) {
                const { analysis } = action.payload.data
                return {
                    ...state,
                    loading: false,
                    analysis
                }
            }
        }
        case TYPES.GET_FB_ANALYSIS_REJECTED: {
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        }

        default:
            return state
    }
}