import React from 'react';
import { connect } from "react-redux";
import {
    Button,
    Alert
} from 'react-bootstrap';
import {
    IoIosSearch
} from 'react-icons/io';
import Lottie from 'react-lottie';
import _ from "lodash";

import '../css/Analyzer.css'
import * as Actions from '../redux/actions'
import loadingLottie from '../images/lottie/loading.json'

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loadingLottie,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

class Analyzer extends React.Component {
    constructor(props) {
        super(props);
        this.pressed = false;
        this.state = {
            isSubmit: false,
            loading: false,
            input: '',
            isError: false,
        }
    }

    handleChange = (event) => {
        this.setState({
            input: event.target.value,
        })
    }

    triggerSearch = () => {
        if(this.state.input === '') {
            this.setState({
                isError: true,
                errorMsg: 'Please enter the page id'
            })
        } else if(this.state.input !== 'hklittlestores') {
            this.pressed = true;
            this.setState({
                isSubmit: true,
                loading: true
            })

            setTimeout(() => {
                this.setState({
                    loading: false,
                    isError: true,
                    errorMsg: 'Cannot load the fan page'
                })
                this.pressed = false;
            }, 1000)
        } else if(!this.pressed) {
            this.pressed = true;
            this.setState({
                isSubmit: true,
                loading: true,
                isError: false
            })
            this.props.getFBAnalysis(this.state.input)

            setTimeout(() => {
                this.setState({
                    loading: false
                })
                this.pressed = false;
            }, 1000)
        }
    }

    render() {
        if(this.state.isSubmit && !this.state.isError && _.size(this.props.analysis) > 0 && !this.state.loading && !this.props.loading) {
            window.location = "/result"
        }

        return (
            <div className="analyzer">
                {
                        this.state.isError ?
                        <div className="error-div">
                            <Alert className="error" variant="danger">
                                {this.state.errorMsg}
                            </Alert>
                        </div>
                        :null
                }

                <div className="input-div">
                    <h3>Paste your Facebook page url for analysis</h3>
                    <h4 className="subtitle">Discover most used words and hashtags in the Facebook page</h4>
                    <br />
                    <input className="input-div-input" placeholder="https://www.facebook.com/{page-id} (e.g. hklittlestores)" onChange={this.handleChange}/>
                    <br />
                    <Button className="button" variant="primary" onClick={this.triggerSearch}>
                        Analyze <IoIosSearch color="white" fontSize="25px"/>
                    </Button>
                </div>

                {
                    this.state.loading || this.props.loading ?
                    <div className="result-div">
                        <Lottie 
                            options={defaultOptions}
                            height={80}
                            width={80}
                            autoPlay
                        />
                    </div>
                    :
                    <div className="result-div">
                    
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.common.loading,

    analysis: state.common.analysis
});

const mapDispatchToProps = dispatch => ({
    getFBAnalysis: (params) => dispatch(Actions.getFBAnalysis(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(Analyzer);