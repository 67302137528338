import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import Favicon from 'react-favicon';
import _ from "lodash";

import './index.css';

import TopBar from './components/TopBar'

import Home from './containers/Home'
import Analyzer from './containers/Analyzer'
import Privacy from './containers/Privacy'
import Terms from './containers/Terms'
import Result from './containers/Result'

import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';

import favicon from './images/favicon/favicon-96x96.png'

const { store, persistor } = configureStore();

global._ = _;

class Main extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        document.title = "FB Content Analysis"
    }

    render() {
        return (
            <Router>
                <head>
                    <Favicon url={favicon} />

                    <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous"
                    />
                </head>

                <div className="body">
                    <TopBar />

                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/analyzer" component={Analyzer} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/tnc" component={Terms} />
                        <Route exact path="/result" component={Result} />
                    </Switch>
                </div>
            </Router>
        )
    }
}

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Main />
        </PersistGate>
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
